import { makeStyles } from "@material-ui/core";
import Home from "../pages/Home";

const Content = () => {
  const classes = contentStyles();

  return (
    <main className={classes.content}>
      <Home />
    </main>
  );
};

export default Content;

const contentStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
  },
}));
