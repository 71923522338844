import {
  Email,
  Reddit,
  LinkedIn,
  Twitter,
  Ballot,
  Cloud,
  DynamicFeed,
  Group,
  Security,
  WbIridescent,
  ViewQuilt,
  Build,
  Devices,
  ExitToApp,
  SettingsApplications,
  Timelapse,
  Translate,
  VideoLibrary,
  PermDataSetting,
  Grain,
  GitHub,
  Link,
  Language,
  AccountBalance,
  SettingsInputAntenna,
  PhonelinkLock,
  VerifiedUser,
  AccountTree,
  EnhancedEncryption,
  Speed,
  CloudUpload,
  Publish,
  SettingsRemote,
  ThumbUp,
  DataUsage,
  Computer,
} from "@material-ui/icons";
import { imgs } from "../assets/imgs";
import Iconrenderer from "../components/iconrenderer";

export const teamSocialIcons = {
  twitter: (
    <Twitter style={{ color: "#00acee", fontSize: "1.2em" }} size="medium" />
  ),
  linkedin: (
    <LinkedIn style={{ color: "#3b5998", fontSize: "1.2em" }} size="medium" />
  ),
  github: <GitHub style={{ color: "#000", fontSize: "1.2em" }} size="medium" />,
  link: (
    <Link
      style={{ color: "rgba(0,0,0,0.80)", fontSize: "1.2em" }}
      size="medium"
    />
  ),
  profile: (
    <Language
      style={{ color: "rgba(0,0,0,0.62)", fontSize: "1.2em" }}
      size="medium"
    />
  ),
};

// ! Change text here to add new announcements
export const announcement = "";

export const sidebarListItems = [
  {
    name: "Product Engineering",
    menuOptions: [
      {
        icon: <Computer color="primary" />,
        name: "System Design & Integration",
      },
      {
        icon: <WbIridescent color="primary" />,
        name: "POC | MVP Design",
      },
      {
        icon: <ViewQuilt color="primary" />,
        name: "User Experience Design",
      },
      {
        icon: <Devices color="primary" />,
        name: "Web & Mobile App",
      },
      {
        icon: <Speed color="primary" />,
        name: "Performance Tuning",
      },
    ],
    icon: <Ballot color="primary" />,
  },
  {
    name: "Cloud",
    icon: <Cloud color="primary" />,
    menuOptions: [
      {
        icon: <AccountBalance color="primary" />,
        name: "Multicloud Strategy & Architecture",
      },
      {
        icon: <Cloud color="primary" />,
        name: "Service Design & Integration",
      },
      {
        icon: <SettingsApplications color="primary" />,
        name: "Service Implementation",
      },
      {
        icon: <CloudUpload color="primary" />,
        name: "Deployment & Scaling",
      },
    ],
  },
  {
    name: "AI | ML",
    menuOptions: [
      {
        icon: <Timelapse color="primary" />,
        name: "Timeseries/Sensor data",
      },
      {
        icon: <Translate color="primary" />,
        name: "NLP (Voice, text, chatbots)",
      },
      {
        icon: <VideoLibrary color="primary" />,
        name: "Image & Video",
      },
      {
        icon: <Grain color="primary" />,
        name: "AI-Edge",
      },
      {
        icon: <PermDataSetting color="primary" />,
        name: "Data Preprocessing, Visualization & Storytelling",
      },
      {
        icon: <DataUsage color="primary" />,
        name: "Data Analytics",
      },
    ],
    icon: <DynamicFeed color="primary" />,
  },
  {
    name: "DevOps",
    icon: <SettingsApplications color="primary" />,
    menuOptions: [
      {
        icon: <AccountTree color="primary" />,
        name: "Continuous Change Integration",
      },
      {
        icon: <Publish color="primary" />,
        name: "Continuous Delivery",
      },
      {
        icon: <ExitToApp color="primary" />,
        name: "Containerized App Deployment",
      },
      {
        icon: <Security color="primary" />,
        name: "DevSecOps",
      },
    ],
  },
  {
    name: "Security",
    icon: <Security color="primary" />,
    menuOptions: [
      {
        icon: <Security color="primary" />,
        name: "Cyber Security",
      },
      {
        icon: <SettingsRemote color="primary" />,
        name: "Information Security",
      },
      {
        icon: <SettingsInputAntenna color="primary" />,
        name: "Web Security",
      },
      {
        icon: <PhonelinkLock color="primary" />,
        name: "Application Security",
      },
      {
        icon: <VerifiedUser color="primary" />,
        name: "Security Audits",
      },
    ],
  },
  {
    name: "Team",
    route: "#team",
    icon: <Group color="primary" />,
  },
  // {
  //   name: "Testimonials",
  //   icon: <Work color="primary" />,
  //   route: "#clients",
  // },
  {
    name: "Contact Us",
    route: "#contact-us",
    enableUi: true,
    icon: <Email color="secondary" />,
  },
];

export const whyChoseList = [
  // {
  //   title: "Technical Consulting",
  //   summary:
  //     "Understanding business needs and defining the right approach for the solution to companies looking to grow, become more efficient and serve their customers better.",
  //   icon: imgs.consult,
  // },
  {
    title: "Bespoke Development",
    summary:
      "Custom digital product development from start to finish tailored to meet your specific needs & to ensure processes & standards. ",
    icon: imgs.bespoke,
  },
  // {
  //   title: "Implementation & Integration",
  //   summary:
  //     "Bringing together people, processes and technology to enable a successful solution implementation. This provides each person in the value chain the most efficient and seamless experience enabling faster delivery.",
  //   icon: imgs.integration,
  // },
  {
    title: "Secure Architecture",
    summary:
      "Product Security is not optional for us. Instead, it's ingrained in product architecture ensuring our client of data privacy and safe transaction",
    icon: imgs.security,
  },
  {
    title: "Supercharged Cloud Infrastructure",
    summary:
      "We exploit the best of cloud infrastructure in a way that cost of high performance is not a deterrent to the core business.",
    icon: imgs.cloud,
  },
  {
    title: "Efficient CI/CD pipeline",
    summary:
      "State of the art continus in tegration and deployemnt so that taking new features to production is done in days instead of months.",
    icon: imgs.pipelines,
  },
];

export const teamProfiles = [
  {
    name: "Amresh Anand",
    details: {
      role: "Head, Technology & Solutions",
      edu: "B.Tech. (VTU), MBA IT (PTU)",
    },
    image:
      "https://res.cloudinary.com/yashwork2110/image/upload/v1621786558/tinyblob/assets/Amresh_c3npvc.jpg",
    summary:
      "Above 16 yrs. of experience in solution engineering and system architecture with global enterprises and startups. ",
    brief: `Amresh has 16+ years of experience in building, testing and deploying Web, Mobile, desktop, server and cloud-based applications application which can scale for millions of requests. He has extensively worked in healthcare domain and highly skilled in cutting edge technology such as JS FullStack, AI, ML, DevOps, etc. Amresh has spent over 12 years in building, leading, mentoring & nurturing teams of skilled engineers in global enterprises (Siemens, Cerner) and many start-ups.
    He has successfully played role of CTO in his last organization, where he wore multiple hats such as Architect, Product Manager, Project Manager and led the Agile team. As CTO, he had also bagged an award for showcasing his excellent leadership in the organisation. Amresh holds a bachelor’s in engineering from Visvesvaraya Technological University and MBA in Information Systems from Punjab Technical University. Heads Product and Engineering.
    Amresh loves trying new technology, listening books and making and breaking things along with learning journey of his twin sons.
    `,
    socials: [
      {
        icon: teamSocialIcons.linkedin,
        link: "https://www.linkedin.com/in/amarstays/",
      },
      {
        icon: teamSocialIcons.twitter,
        link: "https://twitter.com/amarstays",
      },
      {
        icon: teamSocialIcons.github,
        link: "https://github.com/amarstays",
      },
    ],
  },
  {
    name: "Sumit Kumar Jha",
    details: {
      role: "Head (AI) & SME (Fintech)",
      edu: "B.tech (IITKGP), FPM(IIM Shillong)",
    },
    image:
      "https://res.cloudinary.com/yashwork2110/image/upload/v1621795826/tinyblob/assets/sumit_1_yxuian.webp",
    summary:
      "More than 15 yrs. Of Experience Building Intelligent Analytical solutions, iOS based desktop, mobile and web application ",
    brief: `Sumit has worked over 15 years of experience in technology industry including 10+ years in Big Data, Data Analytics, Machine Learning and Artificial Intelligence. A B.Tech.| M.Tech.- IIT Kharagpur & FPM - IIM Shillong in AI/ML modelling on high frequency financial data. His experience spans India, the US and Netherlands, and brings his wealth of expertise to develop products for our clients. Loves to cook, read and anything lawn tennis.`,
    socials: [
      {
        icon: teamSocialIcons.linkedin,
        link: "https://www.linkedin.com/in/sumit-jha-iit05-iim18/",
      },
    ],
  },
  {
    name: "Gunjan Kumar",
    details: {
      role: "SME (Prod. Engr. Web & Mobile)",
      edu: "M.Tech.(IIT KGP) GMP-IT(IIM-Bangalore)",
    },
    image:
      "https://res.cloudinary.com/yashwork2110/image/upload/v1621786567/tinyblob/assets/Gunjan_gg0ytd.jpg",
    summary:
      "15 Years of IT experience in Design and Development of React, React Native, Angular, Java, Android, iOS desktop. ",
    brief: `Gunjan has over 15 Years’ of experience at multiple roles such as Principal Architect, Engineering Manager, Team Leader and Senior Developer. He has extensively worked in technologies such as HTML 5, Java Script, Java Enterprise and Mobile application for multiple Industry Domains across India and UK. He has also worked with various start-ups like Ola , TaxiForSure at their very early stage & brings lot of industry experience working with Adobe, Siemens, Wipro and BP.
    Graduated from General Management program for IT professional (GMIT) from Indian Institute of Management, Bangalore and MTech from Indian Institute of Technology, Kharagpur.
    He Loves reading books, planning holiday trips and play badminton in leisure times`,
    socials: [
      {
        icon: teamSocialIcons.linkedin,
        link: "https://www.linkedin.com/in/kumargun/",
      },
    ],
  },
  {
    name: "Jason Joseph",
    details: {
      role: "SME (Cybersecurity IT services)",
      edu: "B. Tech (NIT Calicut), PGD Cyber law (NLS)",
    },
    image:
      "https://res.cloudinary.com/yashwork2110/image/upload/v1623091120/tinyblob/assets/Jason_i9qzbh.webp",
    summary:
      "Decade of work experience in software, cyber security, finance, general project coordination, IT infrastructure.",
    brief: `Jason is a techno functional engineer with current focus on Cyber security and cyber laws. 
    He’s completed his B. Tech from National Institute of Technology, Calicut with major in Electronics & Communications, 
    Post graduate Diploma in Cyber Law and Cyber Forensic (PGDCLCF) from National Law School (India University), Multiple Programs and course such as Criminal Justice, Cyber Crime etc form IIT Kanpur, advance program in Cyber Law from Asian School and multiple certifications such as CND, CEH, ECSA, CHFI, C-SCRM, OPSEC, CCIO, ISO27001LA, CSFPC and others.
    Winner- Indian Dutch Cybersecurity school Challenge Series 2020, Finalist- Global Cyber Strategy Challenge 2.0.
    `,
    socials: [
      {
        icon: teamSocialIcons.linkedin,
        link: "https://www.linkedin.com/in/jasonjoseph5/",
      },
    ],
  },
  {
    name: "Lark Akarshan",
    details: {
      role: "Creative Head & Design Mentor",
      edu: "",
    },
    image:
      "https://res.cloudinary.com/yashwork2110/image/upload/v1621787597/tinyblob/assets/Lark_COLT_fd1bsp.webp",
    summary:
      "UI / UX | Communication Strategy | Graphics | Brand Philosophy | Design Thinking | Product Design | Motion Graphics | 3D Animation & Modelling. ",
    brief: `Lark is an accomplished entrepreneur and creative director.
    In 2013, Lark co-founded The Prototypes, an award-winning strategy and design practice that helps leaders and their organizations explore, learn, and grow.
    His clients include a variety of Fortune 500 companies (Samsung, BBC, ITC, Orient, Cremica, DLF, Maersk) and organisations including the United Nations, the Estonian Embassy Council American Embassy School and some of the world's most progressive start-ups.
    Lark has served as a board member and advisor to a variety of organizations including The Prototypes, Label KISS, Notesgen, Pepperscript, TAA and SWELL.
    A dynamic writer and lecturer, Lark is frequently engaged as an advisor to entrepreneurs and leaders of some of the largest corporations across the globe.
    He is also a visiting lecturer at Pearl Academy of Design where he teaches Product Interface Design and his Applied Empathy curriculum.
    In his personal time, Lark is an active practitioner of design conjuring, working through his private practice, Fragmants, and leading workshops on how to bring these powerful traditions into a modern life and workplace.
    `,
    socials: [
      {
        icon: teamSocialIcons.linkedin,
        link: "https://www.linkedin.com/in/larkakarshan/",
      },
      {
        icon: teamSocialIcons.link,
        link: "https://larkaakarshan.com/",
      },
      {
        icon: teamSocialIcons.profile,
        link: "https://fragmant.com/",
      },
    ],
  },
];

export const contactUsForm = [
  {
    name: "email",
    type: "email",
    label: "Email",
    placeholder: "Email",
    required: true,
  },
  {
    name: "number",
    type: "number",
    label: "Phone Number",
    placeholder: "Number",
  },
  {
    name: "subject",
    type: "text",
    label: "Subject",
    placeholder: "Subject",
    required: true,
    value: "From tiny blob",
    style: { display: "none" },
  },
  {
    name: "message",
    type: "text",
    label: "Message",
    placeholder: "Message",
    required: true,
    multiline: true,
    rows: 3,
    rowsMax: 10,
  },
];

export const socialIcons = [
  {
    icon: <Twitter style={{ color: "#00acee" }} size="medium" />,
    link: "https://twitter.com/thetinyblob",
  },
  {
    icon: (
      <Iconrenderer url="https://res.cloudinary.com/yashwork2110/image/upload/v1623418523/tinyblob/others/icons8-angellist-50_y69jik.webp" />
    ),
    link: "https://angel.co/company/tinyblob-technologies",
  },
  {
    icon: <LinkedIn style={{ color: "#3b5998" }} size="medium" />,
    link: "https://www.linkedin.com/company/tinyblob/",
  },
];

export const ClientLogo = [
  "https://res.cloudinary.com/yashwork2110/image/upload/v1621792636/tinyblob/others/company-removebg-preview_1_gju1m2.webp",
  "https://res.cloudinary.com/yashwork2110/image/upload/v1621843228/tinyblob/others/matemapper_thbkzi.webp",
  "https://res.cloudinary.com/yashwork2110/image/upload/v1623239066/tinyblob/others/langecole_resized_k88s25.png",
  "https://res.cloudinary.com/yashwork2110/image/upload/v1623254293/tinyblob/others/Altechmind_resize_cgoznl.png",
  "https://res.cloudinary.com/yashwork2110/image/upload/v1623095591/tinyblob/others/WhatsApp_Image_2021-06-08_at_01.14.25_dsmwue.webp",
  "https://res.cloudinary.com/yashwork2110/image/upload/v1623095591/tinyblob/others/WhatsApp_Image_2021-06-08_at_01.14.26_breg7e.webp",
  "https://res.cloudinary.com/yashwork2110/image/upload/v1621843228/tinyblob/others/langjobs_b3chzy.webp",
  "https://res.cloudinary.com/yashwork2110/image/upload/v1621792636/tinyblob/others/company-removebg-preview_1_gju1m2.webp",
  "https://res.cloudinary.com/yashwork2110/image/upload/v1623254293/tinyblob/others/Altechmind_resize_cgoznl.png",
  "https://res.cloudinary.com/yashwork2110/image/upload/v1621843228/tinyblob/others/matemapper_thbkzi.webp",
  "https://res.cloudinary.com/yashwork2110/image/upload/v1623239066/tinyblob/others/langecole_resized_k88s25.png",
  "https://res.cloudinary.com/yashwork2110/image/upload/v1621843228/tinyblob/others/langjobs_b3chzy.webp",
  "https://res.cloudinary.com/yashwork2110/image/upload/v1621792636/tinyblob/others/company-removebg-preview_1_gju1m2.webp",
  "https://res.cloudinary.com/yashwork2110/image/upload/v1621843228/tinyblob/others/matemapper_thbkzi.webp",
  "https://res.cloudinary.com/yashwork2110/image/upload/v1623239066/tinyblob/others/langecole_resized_k88s25.png",
  "https://res.cloudinary.com/yashwork2110/image/upload/v1621843228/tinyblob/others/langjobs_b3chzy.webp",
  "https://res.cloudinary.com/yashwork2110/image/upload/v1621792636/tinyblob/others/company-removebg-preview_1_gju1m2.webp",
  "https://res.cloudinary.com/yashwork2110/image/upload/v1623254293/tinyblob/others/Altechmind_resize_cgoznl.png",
  "https://res.cloudinary.com/yashwork2110/image/upload/v1621843228/tinyblob/others/matemapper_thbkzi.webp",
  "https://res.cloudinary.com/yashwork2110/image/upload/v1623095591/tinyblob/others/WhatsApp_Image_2021-06-08_at_01.14.25_dsmwue.webp",
  "https://res.cloudinary.com/yashwork2110/image/upload/v1623095591/tinyblob/others/WhatsApp_Image_2021-06-08_at_01.14.26_breg7e.webp",
  "https://res.cloudinary.com/yashwork2110/image/upload/v1623239066/tinyblob/others/langecole_resized_k88s25.png",
  "https://res.cloudinary.com/yashwork2110/image/upload/v1621843228/tinyblob/others/langjobs_b3chzy.webp",
  "https://res.cloudinary.com/yashwork2110/image/upload/v1623095591/tinyblob/others/WhatsApp_Image_2021-06-08_at_01.14.25_dsmwue.webp",
  "https://res.cloudinary.com/yashwork2110/image/upload/v1623095591/tinyblob/others/WhatsApp_Image_2021-06-08_at_01.14.26_breg7e.webp",
  "https://res.cloudinary.com/yashwork2110/image/upload/v1623254293/tinyblob/others/Altechmind_resize_cgoznl.png",
];

export const email_validator_regex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const name_validation =
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
export const phone_validation = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
