import { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  makeStyles,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Close from "@material-ui/icons/Close";
import {
  contactUsForm,
  email_validator_regex,
  name_validation,
  phone_validation,
} from "../utils/metadata";

const ContactUs = ({ closeBubble }) => {
  const classes = contactUsStyle();
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState({
    open: false,
  });

  const validation = (values) => {
    const err = { ...errors };
    if (values.fullname === "") err.fullname = "Required";
    else if (!name_validation.test(values.fullname))
      err.fullname = "Not a valid name";
    else delete err.fullname;

    if (values.email === "") err.email = "Required";
    else if (!email_validator_regex.test(values.email))
      err.email = "Not a valid email";
    else delete err.email;

    if (values.phone && !phone_validation.test(values.phone))
      err.phone = "Not a valid email";
    else delete err.phone;

    if (values.subject === "") err.subject = "Required";
    else delete err.subject;

    if (values.message === "") err.message = "Required";
    else if (values.message?.length < 50)
      err.message = "This message is too short";
    else delete err.message;

    setErrors({ ...err });
  };

  const handleChange = ({ target }) => {
    const temp = { ...formData };
    temp[target.name] = target.value;
    validation(temp);

    setFormData({ ...temp });
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    validation(formData);
    if (!Object.keys(errors).length) {
      const form = ev.target;
      const data = new FormData(form);
      const xhr = new XMLHttpRequest();
      xhr.open(form.method, form.action);
      xhr.setRequestHeader("Accept", "application/json");
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== XMLHttpRequest.DONE) return;
        if (xhr.status === 200) {
          form.reset();
          setMessage({
            open: true,
            severity: "success",
            msg: "We'll get back to you soon",
          });
        } else {
          setMessage({
            open: true,
            severity: "error",
            msg: "Sorry! Something went wrong. Please try again.",
          });
        }
      };
      xhr.send(data);
    }
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        className={classes.cardContainer}
      >
        <Card className={classes.formContainer} elevation={3}>
          <CardHeader
            style={{ background: "#3A5985" }}
            title={
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: "center" }}
              >
                <b>Write To Us</b>
              </Typography>
            }
            action={
              <IconButton onClick={() => closeBubble()}>
                <Close color="primary" />
              </IconButton>
            }
          />
          <CardContent>
            <form
              onSubmit={handleSubmit}
              action="https://formspree.io/f/maylzavp"
              method="POST"
            >
              <Grid container spacing={2}>
                {contactUsForm.map((field, index) => (
                  <Grid item xs={12} key={index}>
                    <TextField
                      {...field}
                      className={classes.textField}
                      onChange={handleChange}
                      error={errors[field.name] && errors[field.name] !== ""}
                      helperText={errors[field.name]}
                    />
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="center">
                    <Button
                      variant="contained"
                      color="secondary"
                      type="submit"
                      style={{ color: "white" }}
                    >
                      Send
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Box>
      <Snackbar
        open={message.open}
        autoHideDuration={3000}
        onClose={() => setMessage({ ...message, open: false })}
      >
        <Alert severity={message?.severity}>{message?.msg}</Alert>
      </Snackbar>
    </>
  );
};

export default ContactUs;

const contactUsStyle = makeStyles((theme) => ({
  cardContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "400px",
    [theme.breakpoints.down("sm")]: {
      width: "300px",
    },
  },
  formContainer: {
    height: "fit-content",
  },
  textField: {
    width: "100%",
  },
}));
