import {
  Box,
  Button,
  CardMedia,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import Slider from "./Slider";
import Team from "./Team";
import WhyUs from "./WhyUs";
import "../assets/styles.scss";
import { imgs } from "../assets/imgs";

const Home = () => {
  const classes = homeStyles();

  return (
    <>
      <div className={classes.toolbar} />
      <Box display="flex" justifyContent="center" width="100%" id="home">
        <Box className={classes.bannerContainer}>
          <Box className={classes.bannerContent}>
            <Typography variant="h4">
              We help emerging businesses find innovative solutions to
              real-world problems.
            </Typography>
            <br />
            <Button
              variant="contained"
              color="secondary"
              size="large"
              style={{ width: "max-content" }}
            >
              <a href="#services">Know More</a>
            </Button>
          </Box>
          <img src={imgs.banner} alt="banner" className={classes.bannerImg} />
        </Box>
      </Box>
      <Paper
        id="services"
        elevation={0}
        className={classes.serviceListContainer}
      >
        <WhyUs />
      </Paper>
      <Paper id="team" className={classes.teamListContainer}>
        <Team />
      </Paper>
      <Paper elevation={0} className={classes.sliderContainer}>
        <Slider />
      </Paper>
    </>
  );
};

export default Home;

const homeStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  bannerContainer: {
    height: "90vh",
    display: "flex",
    width: "100%",
    maxWidth: "1700px",
    [theme.breakpoints.down("sm")]: {
      height: "max-content",
      flexDirection: "column",
    },
  },
  bannerImg: {
    height: "60vh",
    width: "auto",
    [theme.breakpoints.up("md")]: {
      height: "90vh",
      width: "50vw",
    },
  },
  bannerContent: {
    paddingLeft: "25px",
    textAlign: "left",
    color: "black",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flext-start",
    height: "90%",
    width: "100%",
    maxWidth: "100vw",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
      textAlign: "center",
      alignItems: "center",
    },
  },
  serviceListContainer: {
    color: "black",
    height: "70vh",
    padding: theme.spacing(2, 3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      height: "max-content",
    },
  },
  sliderContainer: {
    width: "100%",
    paddingTop: "10px",
  },
  teamListContainer: {
    background: "rgba(0,0,0,0.12)",
    padding: "40px 0px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "max-content",
  },
  whyChooseUs: {
    padding: theme.spacing(3),
    paddingTop: "50px",
    height: "35vh",
  },
  list: {
    padding: 0,
  },
  clientsContainer: {
    background: "#ececec",
    padding: "90px 0px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "max-content",
  },
}));
