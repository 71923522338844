import { useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  makeStyles,
  CardHeader,
} from "@material-ui/core";
import { whyChoseList } from "../utils/metadata";
import { Zoom } from "react-awesome-reveal";
import classnames from "classnames";
import "../assets/assetStyles.scss";

const WhyUs = () => {
  const classes = serviceStyles();
  const [hover, setHover] = useState(null);

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="h4" style={{ textAlign: "left" }}>
          <b className="roboto">
            <span className="logo-right">Why</span>{" "}
            <span className="logo-left">Tiny</span>
            <span className="logo-right">Blob</span>
            <span className="logo-left">?</span>
          </b>
        </Typography>
        <br />
        <Typography
          variant="h5"
          color="secondary"
          style={{ width: "90vw", textAlign: "center" }}
        >
          TinyBlob works with large companies, startups, and entrepreneurs to
          build amazing digital experiences. We were born out of the need to
          hire experts on demand for our own high-performing secured application
          on the cloud, and also for the clients we like to work with.
          <b> "Experts on-demand"</b> sums up what we do.
        </Typography>
        <br />
      </Box>
      <Box className={classes.whyList}>
        {whyChoseList.map((reason, index) => (
          <Zoom>
            <Card
              key={index}
              className={classnames(classes.whyItem, "hidden-parent")}
              elevation={hover === index ? 4 : 1}
              onMouseEnter={() => setHover(index)}
              onMouseLeave={() => setHover(null)}
            >
              <CardContent className="hideOnHover">
                <img src={reason.icon} alt="icon" height="65" />
              </CardContent>
              <CardHeader
                className="hideOnHover"
                title={
                  <Typography>
                    <b>{reason.title}</b>
                  </Typography>
                }
              />
              <Typography className="hidden">{reason.summary}</Typography>
            </Card>
          </Zoom>
        ))}
      </Box>
    </>
  );
};

export default WhyUs;

const serviceStyles = makeStyles((theme) => ({
  whyList: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  whyItem: {
    width: "190px",
    minHeight: "220px",
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: theme.spacing(2),
    textAlign: "center",
    padding: theme.spacing(1),
    transition: "background 0.4s",
    "&:hover": {
      background: theme.palette.secondary.main,
      color: "white",
    },
  },
}));
