import {
  Box,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { Email, Room } from "@material-ui/icons";
import { imgs } from "../assets/imgs";
import { socialIcons } from "../utils/metadata";

const Footer = () => {
  const classes = footerStyles();

  return (
    <Paper className={classes.root} elevation={5} id="contact-us">
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Box className="fl-ce">
            <Box
              className={classes.container}
              style={{ alignItems: "flex-start" }}
            >
              <img src={imgs.logo} alt="logo" className={classes.logo} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className={classes.container}>
            <Box display="flex">
              {socialIcons.map((social, index) => (
                <div
                  key={index}
                  className={classes.icon}
                  style={{ marginTop: "10px" }}
                >
                  <a
                    href={social.link}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {social.icon}
                  </a>
                </div>
              ))}
            </Box>
            <br />
            <Box className={classes.flIcon}>
              <Email color="secondary" className={classes.icon} size="medium" />
              <a href="mailto:hey@tinyblob.tech">
                <Typography variant="body1">hey@tinyblob.tech</Typography>
              </a>
            </Box>
            <br />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.flIcon}>
            <Room color="secondary" className={classes.icon} size="medium" />
            <Typography variant="subtitle2" style={{ textAlign: "center" }}>
              16/2 S1 Skandavan, 1st Avenue, Teacher’s Colony Sector 5, HSR
              Layout Bangalore 560034
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.copyright}>
            <Typography variant="subtitle2">
              <Divider />
              &copy; 2021, TinyBlob Technologies Pvt. Ltd. (All Rights Reserved)
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};
export default Footer;

const footerStyles = makeStyles((theme) => ({
  root: {
    background: "rgba(0, 0, 0, 0.42)",
    marginTop: "20px",
    paddingTop: "10px",
  },
  logo: {
    width: "200px",
  },
  txtField: {
    borderColor: "white",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  flIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
  },
  icon: {
    marginRight: "10px",
    color: "white",
  },
  copyright: {
    textAlign: "center",
    color: "white",
  },
}));
