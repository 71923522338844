import { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  makeStyles,
  Button,
  Fab,
  Popper,
  Grid,
  ClickAwayListener,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import classnames from "classnames";
import MenuIcon from "@material-ui/icons/Menu";
import { sidebarListItems } from "../utils/metadata";
import {
  ArrowUpward,
  Message,
  ArrowRight,
  ArrowLeft,
} from "@material-ui/icons";
import { scrollToTop } from "../utils/func";
import ContactUs from "../pages/ContactUs";
import { imgs } from "../assets/imgs";

const Shell = ({ isAnnouncement }) => {
  const classes = shellPageStyles();
  const [popoverProps, setPopoverProps] = useState(null);
  const [activeOption, setActiveOption] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showScrollUpBtn, setShowScrollUpButton] = useState(false);
  const [openBubble, setOpenBubble] = useState(false);

  useEffect(() => {
    console.log(
      "%c GLAD YOU ARE INTERESTED! ",
      "background: #222; color: #bada55; font-size: 1.5em"
    );
    window.onscroll = () => {
      if (!showScrollUpBtn && window.pageYOffset > 100)
        setShowScrollUpButton(true);
      else if (window.pageYOffset === 0) setShowScrollUpButton(false);
    };
  }, []);

  const handleNavbarHover = (target, option, index) => {
    if (option?.menuOptions) {
      setPopoverProps(target);
      setActiveOption(index);
      setSelectedOption(option);
    }
  };

  const resetSelectedOption = () => {
    setPopoverProps(null);
    setActiveOption(null);
    setSelectedOption(null);
    setOpenDrawer(false);
  };

  const handleDrawerOpen = (option) => {
    if (option.enableUi) setOpenBubble(true);
    if (option?.menuOptions) {
      setSelectedOption(option);
    } else {
      setOpenDrawer(false);
    }
  };

  const toggleDrawer = (e) => {
    setOpenDrawer(Boolean(openDrawer) ? null : e.target);
  };

  return (
    <>
      <AppBar
        position="sticky"
        elevation={showScrollUpBtn ? 3 : 0}
        className={classes.appBar}
      >
        <Toolbar>
          <Box display="flex" alignItems="center" width="100%">
            <Box display="flex" alignItems="center">
              <IconButton
                color="inherit"
                onClick={(e) => toggleDrawer(e)}
                className={classes.menuBtnContainer}
              >
                <MenuIcon />
              </IconButton>
              <img
                className={classes.logo}
                src={imgs.logo}
                alt="logo"
                onClick={scrollToTop}
              />
            </Box>
            <Box className={classes.navBtnContainer}>
              {sidebarListItems.map((option, key) => (
                <Button
                  className={classnames(classes.navBtn, {
                    [classes.buttonHover]: option.enableUi,
                  })}
                  key={key}
                  onMouseEnter={(e) => handleNavbarHover(e.target, option, key)}
                  onClick={() => {
                    if (option.enableUi) setOpenBubble(true);
                  }}
                >
                  <a
                    href={option.route}
                    style={{
                      fontWeight: activeOption === key ? 900 : "inherit",
                      textDecoration:
                        activeOption === key ? "underline" : "none",
                    }}
                  >
                    {option.name}
                  </a>
                </Button>
              ))}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Popper
        open={Boolean(popoverProps)}
        anchorEl={popoverProps}
        onClose={resetSelectedOption}
        style={{ marginTop: "20px" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onMouseLeave={resetSelectedOption}
      >
        <ClickAwayListener onClickAway={resetSelectedOption}>
          <Box className={classes.popoverBox}>
            <Grid container spacing={2} className={classes.gridContainer}>
              {selectedOption?.menuOptions?.map((item, index) => {
                return (
                  <Grid item xs={12}>
                    <Button key={index} className={classes.popoverBtn}>
                      {item.icon} &nbsp;
                      {item.name}
                    </Button>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </ClickAwayListener>
      </Popper>
      <Popper
        open={Boolean(openDrawer)}
        anchorEl={openDrawer}
        onClose={resetSelectedOption}
        style={{ marginTop: "20px" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <ClickAwayListener
          onClickAway={() => {
            if (Boolean(openDrawer)) {
              setOpenDrawer(null);
            }
          }}
        >
          <List className={classes.list}>
            {selectedOption && (
              <ListItem button onClick={() => setSelectedOption(null)}>
                <Box display="flex">
                  <Typography>Home</Typography>
                  <ArrowRight style={{ color: "white" }} />
                  <Typography>{selectedOption.name}</Typography>
                </Box>
              </ListItem>
            )}
            {Boolean(openDrawer) &&
              selectedOption?.menuOptions?.map((item, index) => (
                <ListItem button key={index}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText
                    primary={<Typography>{item.name}</Typography>}
                  />
                </ListItem>
              ))}
            {!selectedOption &&
              sidebarListItems.map((item, index) => (
                <ListItem
                  button
                  key={index}
                  onClick={() => handleDrawerOpen(item)}
                  className={classnames({
                    [classes.contactButton]: item.enableUi,
                  })}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography>
                        {item.route ? (
                          <a
                            href={item.route}
                            style={{ fontWeight: item.enableUi ? 900 : 500 }}
                          >
                            {item.name}
                          </a>
                        ) : (
                          item.name
                        )}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            {selectedOption && (
              <ListItem button onClick={() => setSelectedOption(null)}>
                <ListItemIcon>
                  <ArrowLeft style={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText primary={<Typography>Back</Typography>} />
              </ListItem>
            )}
          </List>
        </ClickAwayListener>
      </Popper>
      <Box
        className={classes.contactUsBubble}
        style={{ transform: `scale(${openBubble ? 1 : 0})` }}
      >
        <ContactUs closeBubble={() => setOpenBubble(false)} />
      </Box>
      {showScrollUpBtn && (
        <Fab className={classes.upIcon} onClick={scrollToTop} size="small">
          <ArrowUpward />
        </Fab>
      )}
      <Fab
        className={classes.contactBubble}
        onClick={() => setOpenBubble(!openBubble)}
      >
        <Message />
      </Fab>
    </>
  );
};

export default Shell;

const shellPageStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    padding: "5px 0px",
  },
  logo: {
    height: "60px",
    cursor: "pointer",
  },
  menuBtnContainer: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  navBtnContainer: {
    width: "100%",
    marginLeft: "5px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  contactButton: {
    background: "white",
    color: theme.palette.secondary.main,
    border: `3px solid ${theme.palette.secondary.main}`,
  },
  gridContainer: {
    maxWidth: "fit-content",
  },
  navBtn: {
    margin: "0px 5px",
    color: theme.palette.secondary.main,
    fontSize: "0.8em",
  },
  popoverBox: {
    padding: "10px 5px",
    background: theme.palette.secondary.main,
  },
  popoverBtn: {
    color: "white",
    textAlign: "left",
    minWidth: "max-content",
    with: "100%",
    textTransform: "none",
  },
  upIcon: {
    position: "fixed",
    left: "5vw",
    bottom: "7vh",
    zIndex: 9999,
    background: "#9BBAFA",
    color: "white",
  },
  contactBubble: {
    position: "fixed",
    right: "4vw",
    bottom: "7vh",
    background: "#9BBAFA",
    color: "white",
    zIndex: 9999,
    [theme.breakpoints.down("sm")]: {
      right: "5vw",
    },
  },
  menu: {
    background: theme.palette.secondary.main,
  },
  contactUsBubble: {
    display: "flex",
    justifyContent: "flex-end",
    position: "fixed",
    right: "8vw",
    bottom: "12vh",
    transition: "transform 0.3s",
    transformOrigin: "bottom right",
    zIndex: 9999,
    background: "#9BBAFA",
    color: "white",
    [theme.breakpoints.down("sm")]: {
      right: "15vw",
      bottom: "15vh",
    },
  },
  buttonHover: {
    border: `3px solid ${theme.palette.secondary.main}`,
    borderRadius: "12px",
    transition: "all 0.4s",
    transformOrigin: "center center",
    "&:hover": {
      color: "white",
      background: theme.palette.secondary.main,
    },
  },
  list: {
    background: theme.palette.secondary.main,
    color: "white",
    width: "98vw",
  },
}));
