import {
  AppBar,
  Box,
  IconButton,
  makeStyles,
  MuiThemeProvider,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useState } from "react";
import "./App.scss";
import { theme } from "./assets/theme";
import Footer from "./components/footer";
import Content from "./layout/Content";
import { announcement } from "./utils/metadata";
import Shell from "./views/Shell";

function App() {
  const classes = rootStyles();
  const [showAnnouncement, setAnnouncement] = useState(announcement !== "");

  return (
    <MuiThemeProvider theme={theme}>
      {showAnnouncement && (
        <AppBar position="sticky" elevation={0} className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <Box className={classes.announcement}>
              <Typography className={classes.announcementTxt}>
                {announcement}
              </Typography>
              <IconButton onClick={() => setAnnouncement(false)}>
                <Close color="primary" />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
      )}
      <Shell isAnnouncement={showAnnouncement} />
      <Content />
      <Footer />
    </MuiThemeProvider>
  );
}

export default App;

const rootStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#89AFE0",
  },
  toolbar: {
    padding: 0,
    margin: 0,
  },
  announcement: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#ececec",
    paddingLeft: theme.spacing(2),
  },
  announcementTxt: {
    fontSize: "1.5em",
    fontWeight: 600,
    color: "#ececec",
  },
}));
