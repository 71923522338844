import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { ClientLogo } from "../utils/metadata";

const Slider = () => {
  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.title}>
        <Typography variant="h4">
          <b className="logo-left">Clients who trust in us</b>
        </Typography>
      </Box>
      <Box className="slider">
        <Box className="slide-track">
          {ClientLogo.map((logos, index) => (
            <Box className="slide">
              <div className="slide-img-parent">
                <img
                  src={logos}
                  key={index}
                  style={{ margin: "0 20px" }}
                  alt=""
                  rel="preconnect"
                />
              </div>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Slider;

const useStyles = makeStyles((theme) => ({
  title: {
    display: "flex",
    justifyContent: "center",
    margin: "20px 0px",
  },
}));
