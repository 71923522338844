import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  makeStyles,
  Box,
  CardMedia,
  Modal,
  CardActions,
  IconButton,
  Divider,
  Paper,
} from "@material-ui/core";
import { Close, ArrowRight, ArrowLeft } from "@material-ui/icons";
import { useState } from "react";
import { Fade } from "react-awesome-reveal";
import { teamProfiles } from "../utils/metadata";

const Team = () => {
  const classes = teamStyles();
  const [showModal, setShowModal] = useState(null);

  const handleScroll = (left) => {
    const teamList = document.querySelector("#team-list-scroller");

    if (left) {
      teamList.scroll({
        left: teamList.scrollLeft + 320,
        behavior: "smooth",
      });
    } else {
      teamList.scroll({
        left: teamList.scrollLeft - 320,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <Typography variant="h4">
        <b className="logo-left">Meet Our Team</b>
      </Typography>
      <Box display="flex" justifyContent="center" style={{ maxWidth: "100vw" }}>
        <IconButton
          color="primary"
          style={{ padding: 0 }}
          onClick={() => handleScroll(true)}
        >
          <ArrowLeft fontSize="large" color="secondary" />
        </IconButton>
        <Box className={classes.teamList} id="team-list-scroller">
          {teamProfiles.map((profile, index) => (
            <Fade>
              <Card
                elevation={4}
                key={index}
                className={classes.profileCard}
                style={{ width: "280px" }}
                onClick={() => setShowModal(profile)}
              >
                <img
                  src={profile.image}
                  alt="team"
                  className={classes.cardMedia}
                />
                <CardHeader
                  title={
                    <Typography variant="h6">
                      <b>{profile.name}</b>
                    </Typography>
                  }
                  subheader={
                    <Typography variant="subtitle2" className={classes.role}>
                      <b>{profile.details.role}</b>
                      <br />
                      {profile.details.edu}
                    </Typography>
                  }
                />
                <CardContent>
                  <Typography className={classes.teamDesc}>
                    {profile.summary}
                  </Typography>
                </CardContent>
                <CardActions className={classes.actions}>
                  {profile.socials.map((social, index) => (
                    <a
                      href={social.link}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <IconButton
                        onClick={(e) => e.stopPropagation()}
                        key={index}
                      >
                        {social.icon}
                      </IconButton>
                    </a>
                  ))}
                </CardActions>
              </Card>
            </Fade>
          ))}
        </Box>
        <IconButton
          color="primary"
          style={{ padding: 0 }}
          onClick={() => handleScroll(false)}
        >
          <ArrowRight fontSize="large" color="secondary" />
        </IconButton>
      </Box>
      <Modal
        open={Boolean(showModal)}
        onClose={() => setShowModal(null)}
        className={classes.modal}
      >
        <Paper className={classes.modalCard}>
          <Close
            color="primary"
            className={classes.closeIcon}
            onClick={() => setShowModal(false)}
          />
          <Box className={classes.modalTitle}>
            <Typography variant="h6">
              <b>{showModal?.name}</b>
            </Typography>
          </Box>
          <Box className={classes.modalDetails}>
            <Typography paragraph>{showModal?.brief}</Typography>
          </Box>
          <Divider variant="inset" />
          <Box className={classes.modalCardActions}>
            {showModal?.socials?.map((social, index) => (
              <a href={social.link} rel="noopener noreferrer" target="_blank">
                <IconButton onClick={(e) => e.stopPropagation()} key={index}>
                  {social.icon}
                </IconButton>
              </a>
            ))}
          </Box>
        </Paper>
      </Modal>
    </>
  );
};

export default Team;

const teamStyles = makeStyles((theme) => ({
  list: {
    margin: "30px 0px",
  },
  profileCard: {
    margin: theme.spacing(2),
    transition: "transform 0.4s",
    position: "relative",
    height: "520px",
    cursor: "pointer",
    transform: "scale(0.9)",
    background: "#f2f7ff",
    "&:hover": {
      transform: "scale(1)",
    },
  },
  cardMedia: {
    height: "240px",
    width: "100%",
  },
  teamList: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "99%",
    overflowX: "scroll",
    "-ms-overflow-style": "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  actions: {
    position: "absolute",
    bottom: 0,
    background: "#c7c6c6",
    width: "100%",
    padding: 0,
  },
  teamDesc: {
    fontSize: "0.9em",
    marginTop: "-16px",
  },
  role: {
    fontWeight: 400,
    letterSpacing: "1px",
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalCard: {
    width: "850px",
    position: "relative",
    maxWidth: "80vw",
    maxHeight: "90vh",
    background: "#C4D3E6",
  },
  closeIcon: {
    position: "absolute",
    right: "-15px",
    top: "-15px",
    background: "black",
    borderRadius: "50%",
    padding: "5px",
    cursor: "pointer",
  },
  modalTitle: {
    position: "absolute",
    top: "-25px",
    left: "20px",
    padding: "10px",
    borderRadius: "8px",
    background: "#C4D3E6",
    color: "white",
    letterSpacing: "1px",
    textShadow: "2px 2px 2px #848785",
    boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.75)",
  },
  modalDetails: {
    marginTop: "30px",
    maxHeight: "35vh",
    overflowY: "auto",
    padding: "20px",
  },
  modalCardActions: {
    display: "flex",
    flexDirection: "row-reverse",
    background: "#f2f7ff",
  },
}));
